//@flow
import React from "react";
import { navigate } from "gatsby";
import { ThemeContext, Select } from "grommet";
import { MENU } from "../NavBar/Navbar";
import { HEADING_FAMILY } from "../../theme";

const selectOptions = Object.keys(MENU);

const theme = {
	global: {
		colors: {
			border: {
				light: "transparent",
			}
		},
		font: {
			family: HEADING_FAMILY,
		},
		select: {
			background: "brand-light",
		},
	},
	select: {
		control: {
			extend: {
				width: "100%",
				"max-width": "300px",
				"font-family": HEADING_FAMILY,
				"border-bottom": "solid 1px #000"
			}
		}
	}
};

const FooterNavMenu = (props) => {
	return <ThemeContext.Extend value={theme}>
		<Select
			value="מעבר לעמוד:"
			options={selectOptions}
			dropProps={{ align: { top: "top" }, }}
			size="large"
			border={{ bottom: "solid" }}
			onChange={({ option }) => {
				const item = MENU[option];
				navigate(item.to);
			}}
			{...props}
		/>
	</ThemeContext.Extend>;
};

export default FooterNavMenu;

