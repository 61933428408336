import React from "react";
import styled from "styled-components";
import { breakpoint } from "../../theme";
import NavLink from "./NavLink";
import { color } from "../../theme";

const StyledNavLink = styled(NavLink)`
	padding: 0.375rem 1rem;
	line-height: 1.5;
	font-size: 28px;
	text-shadow: 1px 1px 5px ${color("brand-bg-light", false)};

	display: flex;
	align-items: center;
	justify-content: center;

	${(props) => props["data-centered"] ? "justify-content: center;" : ""}

	${breakpoint("tablet", true)`
		align-items: center;
	`}

	${breakpoint("tablet")`

	`}
`;

const NavBarLink = ({ title, icon: Icon, ...props }) => {

	return <StyledNavLink {...props} activeClassName="nav-bar-link-active">
		{Icon && <Icon size="medium" />}
		{title}
	</StyledNavLink>;
};

export default NavBarLink;
