import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { Text } from "grommet";
import { FormDown, FormUp } from "grommet-icons";
import SocialLinks from "../SocialLinks/SocialLinks";
import { color } from "../../theme";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	z-index: 100;
	height: 120px;
	width: 170px;
	padding: 30px 10px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;

    box-shadow: 1px 1px 4px 2px #777;

	${color("brand-bg-dark", "background-color")}

	transition: top 1s, padding 1.2s;

	${({ isOpen }) => isOpen ? `
		top: 0;
	` : `
		top: -102px;
		padding-bottom: 0;
		justify-content: flex-end;
	`}

	svg {
		cursor: pointer;
	}
`;

const Blocker = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

		${({ isOpen }) => isOpen ? `
			display: none;
		` : `
			display: block;
		`}
`

const FollowSocialLinks = styled(SocialLinks)`
	width: 100%;
`;

const SocialFollowUs = () => {
	const [isOpen, setIsOpen] = useState(true);
	const [isFirstTime, setIsFirstTime] = useState(true);
	const [hoverTimer, setHoverTimer] = useState(null);

	const containerRef = useRef();

	const onClick = useCallback(() => {
		if (!isOpen) {
			setIsOpen(true);
		}
	}, [isOpen]);

	const onClickClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onMouseOver = useCallback(() => {
		if (!isOpen) {
			setHoverTimer(setTimeout(() => {
				setIsOpen(true);
				setHoverTimer(null);
			}, 500));
		}
	}, [isOpen, hoverTimer]);

	const onMouseOut = useCallback(() => {
		if (hoverTimer) {
			clearTimeout(hoverTimer);
			setHoverTimer(null);
		}
	}, [isOpen, hoverTimer]);

	useLayoutEffect(() => {
		setTimeout(() => {
			setIsFirstTime(false);
			setIsOpen(false);
		}, 500);
	}, []);

	useEffect(() => {
		let cb;
		const handleClickOutside = (e) => {
			if (containerRef.current && !containerRef.current.contains(e.target)) {
				setIsOpen(false);
			}
		};

		if (isOpen && !isFirstTime) {
			document.body.addEventListener("click", handleClickOutside);
			cb = () => document.body.removeEventListener("click", handleClickOutside);
		}

		return cb;
	}, [isOpen, isFirstTime]);

	return <Container
		ref={containerRef}
		isOpen={isOpen}
		onClick={onClick}
		onMouseOver={onMouseOver}
		onMouseOut={onMouseOut}>
		<Text color="white" tag="div" size="medium" margin={{ bottom: "small" }}>
			דברו איתנו:
		</Text>

		<FollowSocialLinks services={["instagram", "twitter", "facebook", "whatsapp"]} />

		{isOpen ? <FormUp onClick={onClickClose} /> :
			<FormDown color="white" onClick={onClick} />}

		<Blocker isOpen={isOpen} onClick={onClick} />
	</Container>;
};

export default SocialFollowUs;
