import React, { useState } from "react";
import styled, { css } from "styled-components";
import { breakpoint, color } from "../../theme";

const Container = styled.div`
	cursor: pointer;
	display: none;
	height: 65px;
	position: relative;
	margin-left: auto;
	width: 58px;

	${breakpoint("tablet")`
		display: block;
	`}

	:hover {
		${color("light-4", "bg")}
	}
`;

const burgerLinesActive = css`
	:nth-child(1){
		transform: translateY(6px) rotate(45deg);
	}

	:nth-child(2){
		opacity: 0;
	}

	:nth-child(3){
		transform: translateY(-5px) rotate(-45deg);
	}
`;

const BurgerLine = styled.span`
	display: block;
	height: 2px;
	width: 18px;
	left: calc(50% - 8px);
	position: absolute;
	transform-origin: center;
	transition-duration: 140ms;
	transition-property: background-color, opacity, transform;
	transition-timing-function: ease-out;

	${color("dark-2", "bg")}

	${Container}:hover & {
		${color("brand", "bg")}
	}

	:nth-child(1) {
      	top: calc(50% - 6px);
	}

	:nth-child(2) {
		top: calc(50% - 0px);
	}

    :nth-child(3) {
    	top: calc(50% + 5px);
	}

	${({ isActive }) => isActive && burgerLinesActive}
`;


const Burger = ({ onChange }) => {
	const [isActive, setActive] = useState(false);

	return <Container isActive={isActive} onClick={() => {
		onChange(!isActive);
		setActive(!isActive);
	}}>
		<BurgerLine isActive={isActive} />
		<BurgerLine isActive={isActive} />
		<BurgerLine isActive={isActive} />
	</Container>
};

export default Burger;
