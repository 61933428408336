import { HE_MONTHS } from "../consts";

const transformEdgesNodes = (items, moreTransform = null) => {
	const edges = items.edges || items;

	return edges.length ?
		(edges[0].node ? edges.map((edge) => ({
			...edge.node.fields,
			...edge.node.frontmatter,
			content: edge.node.html,
			image: edge.node.frontmatter.image || edge.node.image,
			...(moreTransform ? moreTransform(edge.node) : {})
		})) : edges) : [];
};

const getHebrewDate = (date) =>
	`${date.getDate()} ${HE_MONTHS[date.getMonth()]}, ${date.getFullYear()}`;

const getImageSharpSrc = (imageSharp) =>
	imageSharp.fluid.srcWebp || imageSharp.fluid.src;

const getImageUrl = (image, fallback, siteUrl) => {
	image = image || fallback;

	image = image ?
		(image.childImageSharp ? getImageSharpSrc(image.childImageSharp) : image) : "";

	return image && (!~image.indexOf("http") ? (siteUrl || "") + image : image);
};

const trackEvent = (event, category, action, label, value) => {
	const dl = window.dataLayer;

	if (dl) {
		dl.push({
			event: `mb-web_${event}`,
			category,
			action,
			label,
			value
		});
	}
};

export {
	transformEdgesNodes,
	getHebrewDate,
	getImageUrl,
	trackEvent,
};
