import { useLayoutEffect } from "react";

const useRunOnDocLoad = (fn) => {
	useLayoutEffect(() => {
		const onDocLoaded = () => {
			fn();
			window.removeEventListener("load", onDocLoaded);
		};

		if (document.readyState === "complete") {
			fn();
		}
		else {
			window.addEventListener("load", onDocLoaded);
		}
	});
};


export default useRunOnDocLoad;
