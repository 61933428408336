import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { isString } from "lodash";
import { getImageUrl } from "../utils";

// type Props = {
// 	className?: string,
// 	loading?: "auto" | "lazy" | "eager",
// 	image: GatsbyImage | string,
// 	alt: string,
// 	background: boolean,
// 	yPosition: string,
// 	fallbackUrl?: string,
// 	children?: Object,
// };

const NewBadge = styled.img`
	width: 25%;
	position: absolute;
	top: 4px;
	right: 4px;
`;

const ImageContainer = styled.div`
	position:relative;
	width: 100%;
	${({ containerStyles }) => containerStyles}
`;

const renderAsImage = (props) => {
	const { image } = props;
	let result;

	if (image && image.childImageSharp) {
		result = <ImageContainer containerStyles={props.containerStyles}>
			<Img className={props.className}
				fluid={image.childImageSharp.fluid}
				alt={props.alt}
				loading={props.loading || "lazy"}/>
			{props.showNew && <NewBadge src="https://res.cloudinary.com/montessoribaby/image/upload/f_auto,q_auto/v1610112820/new" />}
		</ImageContainer>;
	}
	else if (isString(image)) {
		result = <img className={props.className} src={image} alt={props.alt} />;
	}
	else {
		console.log("!!!!!!!!!! INVALID IMAGE: ", image);
		throw new Error("IMAGE is invalid !");
	}

	return result;
};

const BackgroundContainer = styled.div`
	width: 100%;
    background-size: cover;
	background-position-x: center;
	background-position-y: ${({ yPosition }) => yPosition || "center"};
    margin: 0 auto;
`;

const renderAsBackground = (props) => {
	const imgUrl = getImageUrl(props.image, props.fallbackUrl);

	return (<BackgroundContainer
		className={props.className}
		yPosition={props.yPosition}
		style={{
			backgroundImage: `url(${imgUrl})`,
		}}
		role="img"
		aria-label={props.alt}
	>
		{props.children}
	</BackgroundContainer>);
};

const Image = (props) => {
	return props.background ?
		renderAsBackground(props) :
		renderAsImage(props);
};

export default Image;

