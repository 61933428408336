import React from "react";
import styled from "styled-components";
import { Heading } from "grommet";

const StyledHeading = styled(Heading)`
	margin: 10px 0;
`;

const PageHeading = ({ className, children }) => {
	return <StyledHeading responsive level={1} size="medium" className={className}>
		{children}
	</StyledHeading>;
};

export default PageHeading;
