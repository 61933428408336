import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { color, breakpoint } from "../../theme";

const hoverStyles = css`
	font-weight:bold;
	${color("brand")}
`;

const NavLink = styled(Link)`
	display: flex;
  text-decoration: none;

	font-size: ${({ theme, size }) => theme.text[size] ? theme.text[size].size : (size || "1rem")};
	${color("dark-2")}

	${breakpoint("phone")`
		text-decoration: none;
	`}

	:hover {
		${({ $noHoverStyles }) => !$noHoverStyles ?
		hoverStyles : `
				font-weight: inherit;
			`};

    svg {
      ${color("brand", "stroke")}
    }
	}

  svg {
    margin-left: 4px;
  }

	&.nav-bar-link-active {
		svg {
      ${color("light-4", "stroke")}
    }
	}
`;

export default NavLink;
