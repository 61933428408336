import React from "react";
import styled from "styled-components";
import { breakpoint, border } from "../../theme";

const PageSection = styled.section`
  margin: 20px 0 0;
  display: flex;
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "space-around"};
  flex-wrap: wrap;

  ${({ bottomBorder }) => bottomBorder && border()`bottom`}

  padding-bottom: 10px;

  p {
    margin: 8px 0;
  }

  ${breakpoint("tablet")`
    padding-bottom: 20px;
  `}
`;

export default (props) => (<PageSection {...props}>
	{props.children}
</PageSection>);
