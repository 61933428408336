import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { color, breakpoint, border } from "../../theme";
import logoPng from "../../../content/images/logo-black-400.png";
import SocialLinks from "../SocialLinks/SocialLinks";
import FooterNavMenu from "../FooterNavMenu/FooterNavMenu";

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  ${color("brand-bg-light", "background-color")}
  ${border()`top`}
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  position:relative;
  justify-content: space-between;
  padding: 10px 4px;
`;

const FooterContent = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  ${breakpoint("phone")`
    flex-direction: column;
    margin-bottom: 10px;
  `}
`;

const BottomSection = styled.div`
  display: flex;

  ${color("brand-bg-dark", "background-color")}
  height: 45px;
  flex-grow: 0;
  align-items: flex-end;
  padding: 0 4px;
`;

const bottomSectionMixin = css`
  flex-grow: 1;
  width: 30%;
  display: flex;

  ${breakpoint("phone")`
    width: 100%;
    justify-content: center;
    margin-bottom: 25px;
  `}
`;

const Logo = styled.div`
  	${bottomSectionMixin}

	height: 100%;
	display: block;

	${breakpoint("phone")`
		display: flex;
		justify-content: center;
	`}

	img {
		max-width: 200px;
		height: auto;

		${breakpoint("phone")`
		width: 140px;
		`}
	}
`;

const FooterSocialLinks = styled(SocialLinks)`
  ${bottomSectionMixin}

   ${breakpoint("phone")`
    justify-content: space-evenly;
  `}
`;

const LinksMenuContainer = styled.div`
	${bottomSectionMixin}
	align-self: flex-end;
	justify-content:center;
`;

const Rights = styled.span`
  color: #fff;
  font-size: 12px;
`;

const SiteFooter = () => {
	return <Footer>
		<MainSection>
			<FooterContent>
				<Logo>
					<Link to="/">
						<img src={logoPng} alt="logo" />
					</Link>
				</Logo>

				<LinksMenuContainer>
					<FooterNavMenu />
				</LinksMenuContainer>

				<FooterSocialLinks services={["whatsapp", "youtube", "twitter", "instagram", "facebook"]} />
			</FooterContent>
		</MainSection>

		<BottomSection>
			<Rights>כל הזכויות שמורות, מונטסורי בייבי {new Date().getFullYear()}.</Rights>
		</BottomSection>
	</Footer>;
};

export default SiteFooter;
