import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Grommet } from "grommet";
import useSiteMetadata from "../SiteMetadata";
import Footer from "../Footer/Footer";
import Navbar from "../NavBar/Navbar";
import GlobalStyles from "../GlobalStyles/GlobalStyles";
import OpenGraph from "../OpenGraph/OpenGraph";
import AdNetworks from "../AdNetworks/AdNetworks";
import SocialFollowUs from "../SocialFollowUs/SocialFollowUs";
import NotificationProvider from "../Notifications/NotificationProvider";
import theme, { color } from "../../theme.js";

export const Main = styled.main`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  ${color("white", "background-color")}
  box-shadow: 0px 5px 10px 0px #5e8472;
`;

const Layout = (props) => {
	const { title, description } = useSiteMetadata();

	const titleTemplate = props.titleTemplateOverride ?
		props.titleTemplateOverride :
		`${props.titleTemplate ? props.titleTemplate : "%s"} | ${title}`;

	return (
		<Grommet theme={theme}>
			<GlobalStyles />
			<div>
				<Helmet titleTemplate={titleTemplate}>
					<title>{props.title || title}</title>

					<link rel="preconnect" href="https://www.google-analytics.com" crossorigin="use-credentials" />
					<link rel="preconnect" href="https://adservice.google.com" crossorigin="use-credentials" />
					<link rel="preconnect" href="https://www.googletagmanager.com" crossorigin="use-credentials" />

					<meta name="description" content={props.ogTags.description || props.description || description} />
					<meta name="keywords" content={props.keywords || "הדרכת הורים,מונטסורי,חינוך מונטסורי,מונטסורי בבית,montessori,education"} />
					<meta name="apple-mobile-web-app-capable" content="no" />
					<meta name="mobile-web-app-capable" content="yes" />

					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href="https://res.cloudinary.com/gozali/image/upload/w_180/v1561554428/logos/logo-white.png"
					/>
					<link
						rel="apple-touch-icon"
						sizes="192x192"
						href="https://res.cloudinary.com/gozali/image/upload/w_192/v1561554428/logos/logo-white.png"
					/>
					<link
						rel="icon"
						type="image/png"
						href="https://res.cloudinary.com/gozali/image/upload/w_192/v1561554428/logos/logo-white.png"
						sizes="192x192"
					/>
					<link
						rel="icon"
						type="image/png"
						href="https://res.cloudinary.com/gozali/image/upload/w_92/v1561554428/logos/logo-white.png"
						sizes="92x92"
					/>
					<link
						rel="icon"
						type="image/png"
						href="https://res.cloudinary.com/gozali/image/upload/w_64/v1561554428/logos/logo-white.png"
						sizes="64x64"
					/>
					<link
						rel="icon"
						type="image/png"
						href="https://res.cloudinary.com/gozali/image/upload/w_32/v1561554428/logos/logo-white.png"
						sizes="32x32"
					/>
					<link
						rel="icon"
						type="image/png"
						href="https://res.cloudinary.com/gozali/image/upload/w_16/v1561554428/logos/logo-white.png"
						sizes="16x16"
					/>

					<link
						rel="mask-icon"
						href="https://res.cloudinary.com/gozali/image/upload/w_92/v1561554428/logos/logo-white.png"
						color="#ff4400"
					/>

					<meta name="theme-color" content="#22d640" />
					<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
					<meta name="apple-mobile-web-app-status-bar-style" content="#22d640" />

					<link href="https://fonts.googleapis.com/css?family=Amatic+SC:400,700|Frank+Ruhl+Libre:400,700&display=swap" rel="stylesheet" />

					<link href="/manifest.json" rel="manifest" />
				</Helmet>
				<OpenGraph tags={props.ogTags} />

				<SocialFollowUs />
				<Navbar />
				<Main>
					<NotificationProvider>
						{props.children}
					</NotificationProvider>
				</Main>
				<Footer />
				<AdNetworks />
			</div>
		</Grommet>
	);
};

export default Layout;
