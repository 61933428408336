import React, { Fragment, useContext } from "react";
import { graphql } from "gatsby";
import { Heading } from "grommet";
import styled, { css, keyframes } from "styled-components";
import { color, headingFamily, breakpoint } from "../../theme";
import PreviewContext from "../Layout/PreviewContext";
import Image from "../Image/Image";
import useSiteMetadata from "../SiteMetadata";
import PageHeading from "../PageHeading/PageHeading";

export const SiteTitleFragment = graphql`
	fragment SiteTitle on Query {
  		siteTitle: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
    		frontmatter {
      			title
    		}
  		}
	}`;

export const RemoteBannerImageFragment = graphql`
	fragment RemoteBannerImage on MarkdownRemark {
		frontmatter {
			imageYPosition
			imageAlt
		}
		image {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 90, srcSetBreakpoints: [800, 1200, 1800]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
		}
	}`;

export const RemoteBannerImageFragmentSmall = graphql`
	fragment RemoteBannerImageSmall on MarkdownRemark {
		frontmatter {
			imageYPosition
      imageAlt
		}
		image {
			childImageSharp {
				fluid(maxWidth: 400, quality: 80, srcSetBreakpoints: [400]) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}`;

export const RemoteBannerImageFragmentNoTypeSmall = graphql`
	fragment RemoteBannerImageNoTypeSmall on MarkdownRemark {
		frontmatter {
			imageYPosition
      imageAlt
		}
		image {
			childImageSharp {
				fluid(maxWidth: 400, quality: 80, srcSetBreakpoints: [400]) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

const ImageContainer = styled(Image)`
  height: 420px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	${breakpoint("tablet")`
		height: 300px;
	`}

	${breakpoint("phone")`
		height: 220px;
	`}
`;

const titleTemplate = css`
	margin-top:0;
	${color("white")};
	line-height: 1;
	text-shadow: -1px 4px 2px ${color("black", false)};
  z-index: 2;
	position: relative;
	${headingFamily}
`;

const PageTitle = styled(Heading)`
  	margin-right: 25px;
		margin-bottom: 10px;
  	${titleTemplate}
`;

const PageSubTitle = styled(Heading)`
  ${titleTemplate}

	position: absolute;
	bottom: 5px;
	left: 20px;
`;

const StyledPageHeading = styled(PageHeading)`
  ${titleTemplate}
`;

const TitlesContainer = styled.div`
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
`;

const SubHeadingLine = styled.span`
  display: block;
  position: relative;
  margin-bottom: 2px;
  font-size: 2.2rem;
  font-weight: bold;
  margin-right: ${({ indent }) => `${indent * 15}px`};
  ${headingFamily}

  ${breakpoint("phone")`
		font-size: 1.6rem;
	`}
`;

const hlPulsate = keyframes`
  100% {
    color: #FFAA15;
  }
`;

const SubHeadingHighlight = styled.span`
  animation: ${hlPulsate} 4s linear infinite;
   animation-direction: alternate;
`;

const renderSubHeading = (text) => {
	return text.split(" ")
		.map((l, i) => {
			const idx = l.indexOf("ילד");

			return (~idx) ?
				<SubHeadingLine key={l} indent={i + 1}>
					<SubHeadingHighlight>{l.substr(idx, 3)}</SubHeadingHighlight>
					{l.substr(idx + 3)}
				</SubHeadingLine> :
				<SubHeadingLine key={l} indent={i + 1}>{l}</SubHeadingLine>;
		});
};

const Titles = ({ title, heading, subheading }) => {
	const pageTitleSize = (heading && subheading) ? "large" : "xlarge" ;
	return <TitlesContainer>
		<PageTitle level={1} size={pageTitleSize} margin={{ bottom: "medium" }}>
			{title}
		</PageTitle>
		{heading && <StyledPageHeading>{heading}</StyledPageHeading>}
		{subheading && <PageSubTitle level={2} size="large">
			{renderSubHeading(subheading)}
		</PageSubTitle>}
	</TitlesContainer>;
};

const PageBannerWithSiteMetadata = (props) => {
	const { imageUrl } = useSiteMetadata();

	return <ImageContainer
		className={props.className}
		yPosition={props.yPosition}
		image={props.image}
		alt={props.alt}
		fallbackUrl={imageUrl}
		background
	>
		<Titles {...props} />
	</ImageContainer>;
};

const PageBanner = (props) => {
	const isPreview = useContext(PreviewContext);

	return (isPreview ?
		<ImageContainer
			className={props.className}
			yPosition={props.yPosition}
			image={props.image}
			alt={props.alt}
			background
		>
			<Titles {...props} />
		</ImageContainer> :
		<PageBannerWithSiteMetadata {...props} />);
};

export default PageBanner;
