import React from "react";
import styled from "styled-components";
import { FacebookOption, Instagram, Youtube, Twitter } from "grommet-icons";
import { AiOutlineWhatsApp } from "react-icons/ai";
import useSiteMetadata from "../SiteMetadata";

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  a {
    width: 24px;
    height: 24px;
  }

  .whatsapp {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const ICONS = {
	"youtube": Youtube,
	"twitter": Twitter,
	"instagram": Instagram,
	"facebook": FacebookOption,
	"whatsapp": AiOutlineWhatsApp,
};

const SocialLinks = ({ services, className }) => {
	const { social } = useSiteMetadata();

	return <Container className={className}>
		{services.map((s) => {
			const Icon = ICONS[s];

			return (<a
				key={s}
				target="_blank"
				href={social[s]}
				rel="noopener noreferrer"
				className={s}
			>
				<Icon color="white"/>
			</a>);
		})}
	</Container>;
};

export default SocialLinks;
