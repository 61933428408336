import React from "react";
import isFunction from "lodash/isFunction";
import Layout from "../../components/Layout/Layout";
import { getImageUrlFromBanner } from "../templateUtils";

//TODO !!!!!!!! stop destructuring pageProps !!!! - pass as is

export default (converterFn, layoutPropsFn, children, options) =>
	(Component) =>
		(props) => {
			const pageProps = converterFn(props);

			let layoutProps = {};

			if (layoutPropsFn) {
				layoutProps = layoutPropsFn(pageProps);
			}

			const { ogTags, ...restLayout } = layoutProps;

			pageProps.page.image = pageProps.page.image || props.data.markdownRemark.image; //for remote images

			return <Layout title={pageProps.page.title}
				ogTags={{
					title: pageProps.page.title,
					description: pageProps.page.heading || pageProps.page.descriptionRaw,
					image: getImageUrlFromBanner(pageProps.page),
					url: pageProps.page.slug,
					...ogTags,
				}} {...restLayout}>
				<Component {...pageProps} data={props.data} options={options}>
					{isFunction(children) ? children(pageProps, options) : children}
				</Component>
			</Layout>;
		};
