export const colors = {
	"brand-gradient-dark": "linear-gradient(to right, #22d640 0%,#247f34 100%);",
	"brand": "#22d640",
	"brand-dark": "#0e751f",
	"brand-bg-light": "#c3d3c6",
	"brand-bg-lightest": "#dbe9de",
	"brand-bg-ultra-light": "#c3d3c63b",
	"brand-bg-overlay": "rgba(5,36,11,0.55)",
	"brand-bg-dark": "#05240b",
	"dark-bg-half": "rgba(30,32,30,0.50)",
	"light-purple": "#a67fd7c7",
	"main": "#96a7a1",
	text: {
		light: "#fff",
	},
	border: {
		light: "#a6e292c7",
		medium: "#1999",
		dark: "#2a6b17f0",
	}
};

export const breakpoints = {
	wide: {},
	large: {
		value: 1200,
	},
	medium: {
		value: 900,
	},
	small: {
		value: 600,
	},
	xsmall: {
		value: 500,
	},
	xxsmal: {
		value: 400,
	}
};
