import React, { useState } from "react";
import Script from "react-load-script";
import useRunOnDocLoad from "../../hooks/useRunOnDocLoad";

const AdNetworks = () => {
	const [isReady, setIsReady] = useState(false);

	useRunOnDocLoad(() => setIsReady(true));

	return isReady && <Script attributes={{ defer: true }}
		url="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />;
};

export default AdNetworks;
