import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { ThemeContext } from "grommet";
import { Basket } from "grommet-icons";
import NavBarLink from "../Link/NavBarLink";
import Burger from "./Burger";
import logo from "../../../content/images/logo-black-192.png";
import { breakpoint, color, border, headingFamily, getColor } from "../../theme";

//TODO !!!!!!! touch/click outside mobile nav should close it

export const MENU = {
	"בית": { to: "/", activeStyle: { display: "none" }, partiallyActive: false },
	"עלינו": { to: "/about", partiallyActive: true },
	"יעוץ והכשרות": { to: "/services", partiallyActive: true },
	"מאמרים": { to: "/blog", partiallyActive: true },
	"ספריית אונליין": { to: "/library", partiallyActive: true },
	"מוצרים": { to: "/products", partiallyActive: true },
	"מילון מושגים": { to: "/glossary", partiallyActive: true },
	"צור קשר": { to: "/contact", partiallyActive: true },
	"החנות שלנו": { to: "/allsale/", partiallyActive: true, icon: Basket },
};

const Nav = styled.nav`
  ${headingFamily}
  ${color("white", "bg")}
  height: 65px;
  z-index: 30;
  position: relative;

  ${border({ width: "2px" })`bottom`}

  ${breakpoint("tablet", true)`
    align-items: stretch;
    display: flex;
  `}

  ${breakpoint("tablet")`
    ${({ isActive }) => isActive && `
      position: fixed;
      width: 100%;
      height: auto;
      opacity: 0.96;
      box-shadow: -4px 4px 10px 0px #000;
    `}
  `}
`;

const Logo = styled.img`
  height: 59px;
`;

const HomeLink = styled(Link)`
  :hover {
    ${color("light-4", "bg")}
  }
`;

const NavbarSection = styled.section`
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  ${breakpoint("tablet")`
    padding: 0;
  `}
`;

const NavbarMenu = styled.div`
  box-shadow: 0 8px 16px rgba(#000, 0.1);
  padding: 0.5rem 0;
  flex-grow: 0;
  display: none;

  ${breakpoint("tablet", true)`
    align-items: stretch;
    display: flex;
  `}

  ${breakpoint("tablet")`
    flex-grow: 1;
    ${({ isActive }) => isActive && "display: block;"}
  `}
`;

const NavbarStart = styled.div`
  ${breakpoint("tablet", true)`
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
    align-items: stretch;
  `}

  ${breakpoint("tablet")`
	margin-top: 40px;
  `}
`;

const Navbar = () => {
	const [mobileMenuActive, setMobileMenuActive] = useState(false);
	const theme = useContext(ThemeContext);

	return (<Nav
		isActive={mobileMenuActive}
		role="navigation"
		aria-label="main-navigation">
		<NavbarSection>
			<Burger onChange={setMobileMenuActive}/>

			<NavbarMenu
				isActive={mobileMenuActive}>
				<NavbarStart>
					{Object.entries(MENU)
						.map(([key, props]) =>
							<NavBarLink
								{...props}
								key={key}
								title={key}
								activeStyle={{
									...props.activeStyle,
									color: getColor(theme, "light-4"),
								}}
								data-centered={mobileMenuActive}/>)}
				</NavbarStart>
			</NavbarMenu>

			<HomeLink to="/" title="Logo">
				<Logo src={logo} alt="montessori-baby"/>
			</HomeLink>
		</NavbarSection>
	</Nav>);
};

export default Navbar;
